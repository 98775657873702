<script setup lang="ts">
import { onMounted } from "vue";
import { useMainStore } from "~/stores/main";
import { useCartStore } from "~/stores/cartStore";
import screens from "#twcss/theme/screens";

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const route = useRoute();
const mainStore = useMainStore();
const cartStore = useCartStore();

// -----------------------
// composables
// -----------------------
if (config.public.tracker.msClarityId) {
  useHead({
    script: [
      {
        children: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${config.public.tracker.msClarityId}");`,
      },
    ],
  });
}

if (config.public.tracker.ga4) {
  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${config.public.tracker.ga4}`,
        async: true,
      },
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.public.tracker.ga4}');
        `,
      },
    ],
  });
}

if (config.public.tracker.gtm && config.public.tracker.gtmSource) {
  // Pre-connect to optimize GTM loading
  useHead({
    link: [
      {
        rel: "preconnect",
        href: `https://www.googletagmanager.com`,
      },
    ],
  });

  useHead({
    script: [
      {
        innerHTML: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date()
          .getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d
          .createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '${config.public.tracker.gtmSource}?id='+i+dl;f.parentNode
          .insertBefore(j,f);})(window,document,'script','dataLayer',
          '${config.public.tracker.gtm}');
        `,
      },
    ],
  });
}

if (String(config.public.bucketName).includes(".")) {
  useHead({
    link: [
      {
        rel: "sitemap",
        type: "application/xml",
        href: `${String(config.public.bucketName)}Public/sitemap.xml`,
      },
    ],
  });
}

// Pre-connects
useHead({
  link: [
    {
      rel: "preconnect",
      href: `https://www.google.com`,
    },
  ],
});

useHead({
  link: [
    {
      rel: "preconnect",
      href: `https://www.gstatic.com`,
    },
  ],
});

// -----------------------
// computed properties
// -----------------------
const bodyClasses = computed(() => {
  const classes = [`theme-group-${config.public.theme.group}`];

  if (route.path === "/") {
    classes.push("home-page");
  }

  return classes.join(" ");
});

const mainContentMarginTop = computed(() => {
  if (route.path === "/") {
    if (config.public.theme.group === "tnf") {
      return mainStore.isMobile ? 56 : 123;
    }

    return mainStore.isMobile ? 84 : 173;
  }

  return mainStore.isMobile ? 56 : 73;
});

useHead({
  bodyAttrs: {
    class: bodyClasses.value,
  },
});

// -----------------------
// vue events
// -----------------------
watch(
  () => route.path,
  () => {
    document.body.className = bodyClasses.value;
  },
);

const getBreakpoint = () => {
  const sortedByWidth = Object.keys(screens).sort(
    (a, b) => parseInt(screens[a]) - parseInt(screens[b]),
  );
  const matchingBreakpoints = sortedByWidth.filter(
    (size) => window.innerWidth >= parseInt(screens[size]),
  );

  if (matchingBreakpoints.length > 0) {
    return matchingBreakpoints.pop();
  } else if (sortedByWidth.length > 0) {
    return sortedByWidth.shift();
  } else {
    return null;
  }
};

onMounted(() => {
  mainStore.isMobile = window.innerWidth < parseInt(screens.lg);
  mainStore.breakpoint = getBreakpoint();

  window.addEventListener("resize", () => {
    mainStore.breakpoint = getBreakpoint();
    mainStore.isMobile = window.innerWidth < parseInt(screens.lg);
  });
});
</script>

<template>
  <div v-if="mainStore.isMobile !== null" class="min-h-screen flex flex-col">
    <LayoutHeader />
    <section
      class="bg-white lg:pb-8 flex-grow min-h-[500px] mainContent"
      :style="{ 'margin-top': `${mainContentMarginTop}px` }"
    >
      <BasketPanel v-if="!mainStore.isMobile" />
      <slot />
    </section>
    <LayoutFooterTNF v-if="config.public.theme.group === 'tnf'" />
    <LayoutFooterMountex v-if="config.public.theme.group === 'mountex'" />

    <ModalCartMerged
      v-if="cartStore.cartMerged"
      @close="cartStore.clearCartMerged()"
    />
  </div>
</template>
