<template>
  <header
    id="headerElement"
    ref="headerElement"
    class="bg-white w-full z-header"
    :class="{
      [`visibility-${mainStore.headerVisibilityState}`]: true,
      absolute: isHeaderPositionAbsolute,
      fixed: !isHeaderPositionAbsolute,
    }"
    :style="{ top: isHeaderPositionAbsolute ? headerTop + 'px' : null }"
  >
    <!-- Hello bar -->
    <LayoutHelloBar v-if="showHelloBar"></LayoutHelloBar>

    <!-- USP bar -->
    <LayoutUSPBar v-if="showUspBar"></LayoutUSPBar>

    <!-- Menu & submenus -->
    <LayoutDesktopMenu
      :show="isMenuVisible"
      :delayed-hide="delayedMenuHide"
      @hide="isMenuVisible = false"
    ></LayoutDesktopMenu>

    <div
      class="menu-bar grid grid-cols-3 grid-rows-menu-bar justify-items-center items-center bg-brand-secondary"
      :class="{
        'fixed w-full': mainStore.headerVisibilityState === 'only-menu',
      }"
    >
      <div class="menu-search-wrapper flex justify-self-start">
        <template v-if="route.path !== '/rendeles'">
          <!-- Blur layer -->
          <div
            v-if="isMenuVisible"
            class="blur-layer fixed z-blur-background backdrop-blur-xs w-full"
          ></div>

          <nav class="main-menu flex items-center relative">
            <!-- Hamburger button -->
            <button
              class="hamburger-button flex flex-col items-center text-15 w-[83px]"
              :aria-label="$t('menu')"
              @click="isMenuVisible = !isMenuVisible"
              @mouseenter="delayedMenuHide = false"
              @mouseleave="delayedMenuHide = true"
            >
              <i class="m-icon-menu text-30"></i>
            </button>
          </nav>
          <div class="search-bar relative ml-[42px]">
            <SearchBox />
          </div>
        </template>
        <template v-else>
          <a
            class="pl-5 text-brand-link font-medium"
            href="javascript:history.go(-1)"
            >&lt;
            <span class="underline">{{ $t("continue_browsing") }}</span></a
          >
        </template>
      </div>
      <div class="logo">
        <NuxtLink to="/">
          <img
            v-if="themeGroup === 'tnf'"
            :src="`/images/theme/${themeGroup}/logo_desktop_240829.svg`"
            class="h-logo"
            :alt="logoAlt"
            loading="lazy"
            width="108"
            height="50"
          />
          <img
            v-if="themeGroup === 'mountex'"
            :src="`/images/theme/${themeGroup}/logo_desktop_240829.svg`"
            class="h-logo"
            :alt="logoAlt"
            loading="lazy"
            width="186"
            height="53"
          />
        </NuxtLink>
      </div>

      <div class="toolbox flex mr-[21px] justify-self-end">
        <a
          v-if="showStores"
          href="/boltok"
          class="mr-[50px]"
          :aria-label="$t('our_stores')"
        >
          <i class="m-icon-stores text-2xl"></i>
        </a>
        <button
          v-if="route.path !== '/rendeles'"
          :aria-label="$t('cart')"
          type="button"
          class="cursor-pointer mr-[50px]"
          @click="toggleCart"
        >
          <i class="m-icon-cart text-2xl relative">
            <span
              v-if="cartItemsNumber > 0"
              class="absolute h-[16px] w-[16px] bg-brand-highlight text-xs text-white rounded-full right-[-10px] top-[-5px]"
              >{{ cartItemsNumber }}</span
            >
          </i>
        </button>
        <!--
        <a
          v-if="showFavourite"
          href="#"
          class="mr-[50px]"
          :aria-label="$t('favourites')"
        >
          <i class="m-icon-favourite text-2xl"></i>
        </a>
        -->
        <!--
        <button type="button" @click="$emit('show-profile-modal')">
          <i class="m-icon-user text-2xl"></i>
        </button>
        -->
        <a
          :href="`/${config.public.routes.profile}`"
          :aria-label="$t('profile.profile')"
        >
          <i class="m-icon-user text-2xl"></i>
        </a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useMainStore } from "~/stores/main";
import { useCartStore } from "~/stores/cartStore";
import { viewCart as sendViewCartEvent } from "~/composables/useGoogleAnalytics";

// -----------------------
// props & emits
// -----------------------
// defineEmits(["show-profile-modal"]);

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const mainStore = useMainStore();
const route = useRoute();
const cartStore = useCartStore();

// -----------------------
// reactive properties
// -----------------------
const headerElement = ref(null);
const isMenuVisible = ref(false);
const delayedMenuHide = ref(null);
const headerTop = ref(0);
const isHeaderPositionAbsolute = ref(false);
const themeGroup = config.public.theme.group;
const visibilityThreshold = 30;

// -----------------------
// computed properties
// -----------------------
/*
const showFavourite = computed(() => {
  if (route.path === "/rendeles") return false;
  if (config.public.theme.group === "tnf") return false;
  return true;
});
*/

const showStores = computed(() => {
  if (route.path === "/rendeles") return false;
  if (config.public.theme.group === "tnf") return false;
  return true;
});

const logoAlt = computed(() => {
  if (config.public.theme.group === "tnf") return "The North Face webáruház";
  return "Mountex";
});

const showHelloBar = computed(() => {
  return route.path === "/";
});

const showUspBar = computed(() => {
  return route.path === '/' && themeGroup === "mountex";
});

const cartItemsNumber = computed(() => {
  if (typeof cartStore.cartData === "undefined") {
    cartStore.fetchCartData();
  }
  if (cartStore.cartData?.products?.length)
    return cartStore.cartData?.products?.length;
  return 0;
});

// -----------------------
// helper functions
// -----------------------
const toggleCart = () => {
  mainStore.cartVisible = !mainStore.cartVisible;

  if (mainStore.cartVisible) {
    sendViewCartEvent();
  }
};

// -----------------------
// hooks
// -----------------------
watch(isMenuVisible, (visible) => {
  if (!visible) {
    isHeaderPositionAbsolute.value = false;
  }
});

onMounted(() => {
  let lastScrollTop = 0;
  const headerHeight = headerElement.value.clientHeight;

  if (route.path !== "/") {
    mainStore.headerVisibilityState = "only-menu";
  }

  window.addEventListener("scroll", () => {
    const scrollTop =
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    const scrollDown = lastScrollTop < scrollTop;
    const beyondThreshold =
      Math.abs(lastScrollTop - scrollTop) >= visibilityThreshold;

    if (scrollDown && isMenuVisible.value) {
      if (isHeaderPositionAbsolute.value === false) {
        // Set header position only once
        isHeaderPositionAbsolute.value = true;
        headerTop.value = scrollTop;
      }

      return;
    } else {
      isHeaderPositionAbsolute.value = false;
    }

    if (!beyondThreshold) {
      return;
    }

    lastScrollTop = scrollTop;

    if (scrollDown) {
      mainStore.headerVisibilityState = "hide";
    } else if (scrollTop <= headerHeight) {
      mainStore.headerVisibilityState = "show";
    } else {
      mainStore.headerVisibilityState = "only-menu";
    }
  });
});
</script>
